import React from "react"
import Layout from "../components/layouts"
import { Hero } from "../components/slices"
import { Link } from "gatsby"

export default () => {
  const data = {
    title: "404 Page not found",
  }

  return (
    <Layout>
      <Hero data={data} className={"-compact"} />
      <div className="content">
        <div className="container">
          <h2 className="[ title -h4 -center ]">
            We couldn't find that page..
          </h2>
          <div className="[ button-holder -centered ]">
            <Link to="/" className="button">
              Return to homepage
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
